// Libraries
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { navigate } from '@reach/router';

// Utilities
import { getTotalTickets, getTotalPrice } from 'utils/tickets';

// Components
import ErrorContainer from 'buyFlow/containers/ErrorContainer';
import CurrentOrder from 'buyFlow/components/CurrentOrder';
import LotteryBanner from 'buyFlow/components/LotteryBanner';
import BottomNav from 'buyFlow/components/BottomNav';
import FooterText from 'common/components/FooterText';
import AlertContainer from 'buyFlow/containers/AlertContainer';
import CartTrail from 'buyFlow/components/CartTrail';
import RestrictedPage from 'buyFlow/components/RestrictedPage';

// State
import * as orderingActions from 'ducks/ordering/actions';
import * as loadingActions from 'ducks/loading/actions';
import * as stageActions from 'ducks/stage/actions';
import * as modalActions from 'ducks/modals/actions';
import * as errorActions from 'ducks/error/actions';
import * as credentialActions from 'ducks/credentials/actions';
import { checkOrderedPacksForDependancies } from 'ducks/ordering/selectors';

// Styling
import styles from './styles.module.scss';

const getTitle = (location, pathEntries) =>
  pathEntries.find(
    ({ path }) =>
      path === location.pathname || (path === '/buy-flow' && location.pathname === '/buy-flow/')
  );

const getCurrentCartIndex = location => pathEntries =>
  pathEntries.findIndex(
    ({ path }) =>
      path === location.pathname ||
      (path === '/buy-flow' && location.pathname === '/buy-flow/') ||
      (path === '/buy-flow/confirm-order' && location.pathname === '/buy-flow/payment-complete')
  );

const generateDescriptionsFromDraws = draws =>
  draws
    .map((draw, index) => ({
      desc: draw.get('description'),
      path: index === 0 ? '/buy-flow' : `/buy-flow/tickets-${index}`,
      tagLine: draw.get('pageBannerTagline')
    }))
    .toArray();

class BuyFlowContainer extends PureComponent {
  onNext = () => {
    const {
      order,
      displayCannotProgress,
      draws,
      stage,
      setStage,
      addAlert,
      location,
      dependancies
    } = this.props;

    const onFinalPurchaseStage = location.pathname === `/buy-flow/tickets-${draws.size - 1}`;

    if (getTotalTickets(order) === 0) {
      addAlert({
        text: 'You must add an item to your cart to proceed.'
      });
    } else if (onFinalPurchaseStage && dependancies.length !== 0) {
      const dependancy = dependancies[0];
      addAlert({
        text: `You must purchase at least one ${dependancy.get('description')} ticket to proceed.`
      });
      displayCannotProgress(true);
    } else if (getTotalTickets(order) > 0 && dependancies.length === 0) {
      navigate(`/buy-flow/purchase-order`);
      setStage(stage + 1);
    }
  };

  onBack = () => {
    window.history.back();
  };

  render() {
    const {
      programLogo,
      order,
      footerLinks,
      draws,
      displayContactModal,
      toggleContactModal,
      location,
      isLoading,
      jwt,
      render
    } = this.props;

    const renderComponent = order && jwt && !isLoading;

    const trimmedPathDescEntries = [
      ...generateDescriptionsFromDraws(draws),
      { desc: 'Purchaser Information', path: '/buy-flow/purchase-order' },
      { desc: 'Order Summary', path: '/buy-flow/confirm-order' }
    ];

    const fullPathEntries = trimmedPathDescEntries.concat([
      {
        desc: 'Payment Summary',
        path: '/buy-flow/payment-complete'
      }
    ]);

    const isLocationValid = getCurrentCartIndex(location);

    if (isLocationValid(fullPathEntries) === -1) {
      return <RestrictedPage />;
    }

    return (
      <>
        <LotteryBanner
          getTitle={getTitle(location, fullPathEntries)}
          programLogo={programLogo}
          onBack={this.onBack}
          totalPrice={getTotalPrice(order)}
          scrolling={this.scrollingBody}
        />
        <ErrorContainer {...this.props}>
          {renderComponent && (
            <CartTrail
              currentIndex={getCurrentCartIndex(location)}
              pathDescEntries={trimmedPathDescEntries}
              drawSize={draws.size}
            />
          )}

          <div className={styles.renderContainer}>
            {render({
              ...this.props,
              onNext: this.onNext,
              onBack: this.onBack,
              renderComponent
            })}
          </div>

          {renderComponent && (
            <div className={styles.currentOrderWrapper}>
              <CurrentOrder
                order={order}
                draws={draws}
                hideCurrentOrder={
                  location.pathname === '/buy-flow/payment-complete' ||
                  location.pathname === '/buy-flow/confirm-order'
                }
                location={location}
              />
            </div>
          )}
        </ErrorContainer>

        <BottomNav
          {...footerLinks}
          toggleContactModalState={toggleContactModal}
          displayContactModal={displayContactModal}
        />

        <FooterText />

        <AlertContainer
          {...this.props}
          checkOrderedPacksForDependancies={this.checkOrderedPacksForDependancies}
        />
      </>
    );
  }
}

BuyFlowContainer.propTypes = {
  draws: PropTypes.shape({}).isRequired,
  order: PropTypes.shape({}).isRequired,
  statusEndpoint: PropTypes.arrayOf(PropTypes.string).isRequired,
  programLogo: PropTypes.string,
  hasMaxPack: PropTypes.bool,
  footerLinks: PropTypes.shape({}).isRequired,
  displayContactModal: PropTypes.bool.isRequired,
  toggleContactModal: PropTypes.func.isRequired,
  toggleDisplayOrder: PropTypes.func.isRequired,
  addAlert: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  location: PropTypes.shape({}).isRequired,
  render: PropTypes.func.isRequired,
  displayCannotProgress: PropTypes.func.isRequired,
  stage: PropTypes.number.isRequired,
  setStage: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  jwt: PropTypes.string,
  dependancies: PropTypes.arrayOf(ImmutablePropTypes.map)
};

BuyFlowContainer.defaultProps = {
  hasMaxPack: null,
  isLoading: true,
  jwt: '',
  programLogo: null,
  dependancies: []
};

const mapStateToProps = ({
  loadingState,
  modalState,
  orderingState,
  stageState,
  errorState,
  credentialsState,
  menuState
}) => ({
  ...loadingState,
  ...modalState,
  ...orderingState,
  ...stageState,
  ...errorState,
  ...credentialsState,
  ...menuState,
  dependancies: checkOrderedPacksForDependancies({ orderingState, modalState })
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...orderingActions,
      ...modalActions,
      ...errorActions,
      ...loadingActions,
      ...stageActions,
      ...credentialActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuyFlowContainer);
