import React from 'react';
import PropTypes from 'prop-types';

const ScrollToTop = React.memo(({ children, location }) => {
  React.useEffect(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      }),
    [location.pathname]
  );
  return children;
});

ScrollToTop.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired
};

export default ScrollToTop;
