// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import ContactModal from 'marketing/components/ContactModal';

// Styling
import { link, linkWrapper, container } from './styles.module.css';

const BottomNav = React.memo(
  ({ rulesPdf, privacyPolicyPdf, details, toggleContactModalState, displayContactModal }) => (
    <div className={container}>
      {rulesPdf && (
        <div className={linkWrapper}>
          <a href={rulesPdf} className={`${link} theme-color-primary`} target="__blank">
            RULES
          </a>
        </div>
      )}
      {privacyPolicyPdf && (
        <div className={linkWrapper}>
          <a href={privacyPolicyPdf} className={`${link} theme-color-primary`} target="__blank">
            PRIVACY POLICY
          </a>
        </div>
      )}
      <div className={linkWrapper}>
        <ContactModal
          details={details}
          toggleContactModalState={toggleContactModalState}
          displayContactModal={displayContactModal}
        />
      </div>
    </div>
  )
);

BottomNav.propTypes = {
  details: PropTypes.string,
  rulesPdf: PropTypes.string,
  privacyPolicyPdf: PropTypes.string,
  displayContactModal: PropTypes.bool.isRequired,
  toggleContactModalState: PropTypes.func.isRequired
};

BottomNav.defaultProps = {
  rulesPdf: '',
  privacyPolicyPdf: '',
  details: ''
};

export default BottomNav;
