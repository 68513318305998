// Libraries
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BuyFlowAlert from 'buyFlow/components/BuyFlowAlert';

// Components

class AlertContainer extends PureComponent {
  render() {
    const { alerts, closeAlert } = this.props;

    return alerts.map(({ text, alertComponent }, index, arr) =>
      !alertComponent ? (
        <BuyFlowAlert
          isOpen
          index={index}
          key={`alert_${text}`}
          hideAlert={index !== arr.length - 1}
          onClose={() => closeAlert({ index })}
          text={text}
        />
      ) : (
        <BuyFlowAlert
          key={`componentAlert_${text}`}
          isOpen
          index={index}
          hideAlert={index !== arr.length - 1}
          onClose={() => closeAlert({ index })}
          component={() => alertComponent({ ...this.props, onClose: () => closeAlert({ index }) })}
        />
      )
    );
  }
}

AlertContainer.propTypes = {
  alerts: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string
    })
  ),
  closeAlert: PropTypes.func.isRequired
};

AlertContainer.defaultProps = {
  alerts: []
};

export default AlertContainer;
