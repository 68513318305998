// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Components
import Button from 'common/components/Button';
import BestDeal from 'buyFlow/components/BestDeal';

import { isMonoPack } from 'utils/tickets';

// Styling
import styles from './styles.module.scss';

const TicketOrder = React.memo(
  ({
    generatedTickets,
    bestDeal,
    onSetOrder,
    handleNextButton,
    handleBackButton,
    location,
    drawCode
  }) => {
    const monoPacks = generatedTickets.filter(t => isMonoPack(t.props.ticket));
    const nonMonoPacks = generatedTickets.filter(t => !isMonoPack(t.props.ticket));

    return (
      <div className={styles.ticketGridContainer}>
        <div className={styles.ticketGrid}>
          {monoPacks.size > 0 && <div className={styles.monoPackContainer}>{monoPacks}</div>}
          {nonMonoPacks.size > 0 && (
            <div className={styles.nonMonoPackContainer}>{nonMonoPacks}</div>
          )}
        </div>
        <BestDeal
          text={bestDeal.get('dealText')}
          bonusTickets={bestDeal.get('bonusTickets')}
          priceSavings={bestDeal.get('priceSavings')}
          onSetOrder={() => onSetOrder(bestDeal.get('optimizedOrder'), drawCode)}
        />
        <div className={styles.buttonCenter}>
          {location.pathname !== '/buy-flow/' && location.pathname !== '/buy-flow' && (
            <Button
              text="Back"
              modifiers="button buyflow inverted"
              extraClasses="theme-border-secondary"
              onClick={() => {
                handleBackButton(drawCode);
              }}
            />
          )}
          <Button
            text="Next"
            modifiers="button buyflow"
            extraClasses="theme-background-secondary"
            onClick={() => {
              handleNextButton(drawCode);
            }}
          />
        </div>
      </div>
    );
  }
);

TicketOrder.propTypes = {
  generatedTickets: ImmutablePropTypes.list.isRequired,
  bestDeal: ImmutablePropTypes.map.isRequired,
  onSetOrder: PropTypes.func.isRequired,
  handleNextButton: PropTypes.func.isRequired,
  drawCode: PropTypes.number.isRequired,
  handleBackButton: PropTypes.func.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired
};

export default TicketOrder;
