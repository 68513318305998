// Libraries
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import ImmutableProps from 'react-immutable-proptypes';
// Components
import PurchaserForm from 'buyFlow/components/PurchaserForm';
import MembershipAlert from 'buyFlow/components/MembershipAlert';

// Utilities
import { errors } from 'utils/api';
import { getTotalTickets } from 'utils/tickets';

// Data
import { raffle } from 'data/data.json';

// Styles
import styles from './styles.module.scss';

class PurchaseFormContainer extends PureComponent {
  onSubmitPurchaserForm = values => {
    const {
      setPurchaserInfo,
      setError,
      toggleMembershipModal,
      includeMembershipSubscriptions,
      addAlert,
      order,
      dependancies
    } = this.props;

    // Feature currently on hold:
    // const shouldValidateIp = raffle.strictLocation === 'ontario';
    const shouldValidateIp = false;

    if (shouldValidateIp && !this.validatePurchaserIp()) {
      setError(errors.IP);
      return;
    }

    if (dependancies.length !== 0) {
      const dependancy = dependancies[0];
      addAlert({
        text: `You must purchase at least one ${dependancy.get('description')} ticket to proceed.`
      });
      return;
    }

    if (getTotalTickets(order) === 0) {
      addAlert({
        text: 'You must add an item to your cart to proceed.'
      });
      return;
    }

    setPurchaserInfo(values);

    if (includeMembershipSubscriptions) {
      toggleMembershipModal();
      addAlert({
        text: 'MembershipModal',
        alertComponent: props => <MembershipAlert {...props} />
      });
    } else {
      navigate(`/buy-flow/confirm-order`);
    }
  };

  validatePurchaserIp = () => {
    const validLocation = raffle.strictLocation;

    // geoip is defined in a script tag, but eslint does not detect this
    // eslint-disable-next-line
    return geoip2.city(
      data =>
        data.subdivisions.some(subdivision => subdivision.names.en.toLowerCase() === validLocation),
      () => false
    );
  };

  render() {
    const { attributes, setPurchaserInfo, purchaserInfo, onBack } = this.props;

    return (
      <div>
        <h4 className={`theme-color-primary ${styles.title}`}> Purchaser Information </h4>
        <PurchaserForm
          onSubmit={this.onSubmitPurchaserForm}
          onBlur={() => values => setPurchaserInfo(values)}
          attributes={attributes}
          initialValues={purchaserInfo}
          handleBackButton={onBack}
        />
      </div>
    );
  }
}

PurchaseFormContainer.propTypes = {
  attributes: PropTypes.shape({}).isRequired,
  purchaserInfo: PropTypes.shape({}),
  toggleMembershipModal: PropTypes.func.isRequired,
  setPurchaserInfo: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  includeMembershipSubscriptions: PropTypes.bool,
  addAlert: PropTypes.func.isRequired,
  dependancies: PropTypes.arrayOf(ImmutableProps.list),
  order: ImmutableProps.list.isRequired
};

PurchaseFormContainer.defaultProps = {
  includeMembershipSubscriptions: false,
  purchaserInfo: null,
  dependancies: []
};

export default PurchaseFormContainer;
