// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Styling
import { button } from './styles.module.scss';

const TicketButton = React.memo(
  ({ icon = 'remove', onClick, disabled, classStyles, noBackgroundColor }) => (
    <button
      type="button"
      className={classnames(
        `material-icons icon ${button}`,
        classStyles,
        !noBackgroundColor ? 'theme-background-secondary' : {}
      )}
      icon={icon}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
    </button>
  )
);

TicketButton.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  classStyles: PropTypes.string,
  noBackgroundColor: PropTypes.bool
};

TicketButton.defaultProps = {
  disabled: false,
  classStyles: '',
  noBackgroundColor: false
};

export default TicketButton;
