// Libraries
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { navigate } from '@reach/router';

// Components
import Icon from 'common/components/Icon';
import CurrentOrder from 'buyFlow/components/CurrentOrder';

// Utilities
import { getTotalTickets } from 'utils/tickets';

// Styling
import styles from './styles.module.scss';

class Subscriptions extends PureComponent {
  handleCheckout = e => {
    const {
      addSubscriptionsToOrder,
      onClose,
      toggleSubscriptionsModal,
      dependancies,
      addAlert,
      subscriptions
    } = this.props;

    e.stopPropagation();

    if (getTotalTickets(subscriptions) === 0) {
      return addAlert({
        text: 'You must add an item to your cart to proceed.'
      });
    }

    if (dependancies && dependancies.length === 0) {
      addSubscriptionsToOrder(true);
      toggleSubscriptionsModal();
      onClose();
      return navigate(`/buy-flow/confirm-order`);
    }

    const dependancy = dependancies[0];
    return addAlert({
      text: `You must purchase at least one ${dependancy.get('description')} ticket to proceed.`
    });
  };

  handleCancel = e => {
    const { onClose, toggleSubscriptionsModal } = this.props;

    e.stopPropagation();
    toggleSubscriptionsModal();
    onClose();
    navigate(`/buy-flow/confirm-order`);
  };

  render() {
    const { subscriptions, ...rest } = this.props;

    return (
      <div className={styles.container}>
        <Icon className={`${styles.icon} theme-color-tertiary`} icon="stars" />
        <p className={`h4 theme-color-primary ${styles.heading}`}>Become a Member</p>
        <p>
          Secure your order for future lotteries. Your credit card will be charged at the beginning
          of future lotteries so you never miss out!
        </p>
        <div className={styles.currentSubscriptionOrderWrapper}>
          <CurrentOrder {...rest} title="Your Future Order" order={subscriptions} />
        </div>
        <p className={styles.footerHeading}>
          Today, your card will only be charged for ticket purchases you made for the current
          lottery.
        </p>
        <button
          type="button"
          onClick={this.handleCheckout}
          className={`button button--buyflow theme-background-secondary ${styles.button}`}
        >
          Checkout With Subscriptions
        </button>
        <button
          type="button"
          onClick={this.handleCancel}
          className={`button button--buyflow button--inverted theme-border-secondary ${
            styles.button
          }`}
        >
          Checkout Without Subscriptions
        </button>
      </div>
    );
  }
}

Subscriptions.propTypes = {
  order: ImmutablePropTypes.list.isRequired,
  subscriptions: ImmutablePropTypes.list.isRequired,
  onClose: PropTypes.func.isRequired,
  addSubscriptionsToOrder: PropTypes.func.isRequired,
  dependancies: PropTypes.arrayOf(ImmutablePropTypes.list),
  toggleSubscriptionsModal: PropTypes.func.isRequired,
  addAlert: PropTypes.func.isRequired
};

Subscriptions.defaultProps = {
  dependancies: []
};

export default Subscriptions;
