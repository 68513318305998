// Libraries
import { fromJS } from 'immutable';

// Utilities
import { get, errors } from 'utils/api';
import { denormalize } from 'utils/helpers';
import {
  getTotalTickets,
  setOrderContentsForDraw,
  orderUpgradeMap,
  isMonoPack,
  getQuantity,
  getMonoPacksByDrawId,
  generateMaxPack,
  isSoldOut,
  isMaxPack
} from 'utils/tickets';

// Actions
import { toggleLoading } from 'ducks/loading/actions';
import { setError } from 'ducks/error/actions';
import { setJwt } from 'ducks/credentials/actions';
import { addAlert } from 'ducks/modals/actions';
import { setOrderEndpoint, setOrder, setUpgradeMap } from 'ducks/ordering/actions';
import { getDependancies } from 'ducks/ordering/selectors';

export const getSequential = (urls, hasMaxPack, urlIndex = 0) => async (dispatch, getState) => {
  try {
    const {
      orderingState: { draws, order }
    } = getState();

    const url = urls[urlIndex];

    if (!urlIndex) {
      dispatch(toggleLoading());
    }

    const res = await get(url);

    const status = fromJS(res.data);
    const dataWithOrders = status.get('packs').map(pack => pack.set('ordered', 0));
    const orderWithStatus = denormalize(order, 'code', dataWithOrders);

    const orderWithDrawDesc = orderWithStatus.map(pack =>
      pack.update('packDrawTypes', list => denormalize(list, 'code', draws))
    );

    const orderWithMaxPack =
      hasMaxPack && orderWithDrawDesc.push(generateMaxPack(orderWithDrawDesc));

    return [
      setUpgradeMap(orderUpgradeMap(orderWithStatus)),
      setJwt(status.get('jwt')),
      setOrderEndpoint(status.get('epOrder')),
      setOrder(orderWithMaxPack || orderWithDrawDesc),
      toggleLoading()
    ].forEach(func => dispatch(func));
  } catch (err) {
    if (urls.length > 0 && urlIndex + 1 < urls.length) {
      return dispatch(getSequential(urls, hasMaxPack, urlIndex + 1));
    }
    dispatch(setError(errors.STATUS));
    throw err;
  }
};

export const updateQuantity = (id, amount) => (dispatch, getState) => {
  const {
    orderingState,
    modalState: { displaySubscriptionsModal }
  } = getState();

  const order = !displaySubscriptionsModal ? orderingState.order : orderingState.subscriptions;
  const { draws } = orderingState;

  const indexOfPack = order.findIndex(pack => pack.get('code') === id);
  const pack = order.get(indexOfPack);

  if (!isMonoPack(pack) && pack.get('status') === 'S') {
    return null;
  }

  const newOrdered = Math.max(pack.get('ordered') + amount, 0);

  let newState = order.setIn([indexOfPack, 'ordered'], newOrdered);

  // if a monopack quantity is being increased, redistribute tickets
  // in the same draw.
  if (isMonoPack(pack)) {
    const drawId = pack.getIn(['packDrawTypes', 0, 'code']);
    let drawPacks = getMonoPacksByDrawId(drawId, newState).sortBy(p => -getQuantity(p));

    drawPacks = !displaySubscriptionsModal ? drawPacks.filter(p => !isSoldOut(p)) : drawPacks;
    let totalTickets = getTotalTickets(drawPacks);

    // zero out the orders and allocate tickets
    drawPacks = drawPacks
      .map(p => p.set('ordered', 0))
      .map(p => {
        const quantity = getQuantity(p);
        const ordered = Math.floor(totalTickets / quantity);
        if (ordered > 0) {
          totalTickets %= quantity;
          return p.set('ordered', ordered);
        }
        return p;
      });

    newState = setOrderContentsForDraw(drawPacks, newState, drawId);
  }

  const dependancies = getDependancies(draws, newState);

  if ((dependancies && dependancies.length === 0) || isMaxPack(pack)) {
    return dispatch(setOrder(newState, displaySubscriptionsModal));
  }

  const dependancy = dependancies[0];
  return dispatch(
    addAlert({
      text: `You must purchase at least one ${dependancy.get('description')} ticket to proceed.`
    })
  );
};

/**
 * Handles pack ordering from the Current Order Buy Menu
 * @param {String} id
 * @param {Number} amount
 * @param {Object} location
 */

export const updateDrawQuantity = (id, amount) => (dispatch, getState) => {
  const {
    orderingState,
    modalState: { displaySubscriptionsModal }
  } = getState();

  const order = !displaySubscriptionsModal ? orderingState.order : orderingState.subscriptions;

  const monoPacks = !displaySubscriptionsModal
    ? getMonoPacksByDrawId(id, order).filter(p => !isSoldOut(p))
    : getMonoPacksByDrawId(id, order);

  // Handles additions to the order

  if (amount === 1) {
    const availablePack = !displaySubscriptionsModal
      ? monoPacks.find(p => p.get('status') === 'A')
      : monoPacks.first();
    return availablePack && dispatch(updateQuantity(availablePack.get('code'), amount));
  }

  // Handle subtraction to the order

  let packsSortedQtyDesc = monoPacks.sort(
    (a, b) => b.getIn(['packDrawTypes', 0, 'quantity']) - a.getIn(['packDrawTypes', 0, 'quantity'])
  );

  packsSortedQtyDesc = !displaySubscriptionsModal
    ? packsSortedQtyDesc.filter(p => !isSoldOut(p))
    : packsSortedQtyDesc;

  let totalTickets = getTotalTickets(monoPacks) - 1;

  const newOrderedDraws = packsSortedQtyDesc
    .map(p => p.set('ordered', 0))
    .map(pack => {
      const qty = getQuantity(pack);
      if (totalTickets >= qty) {
        const updatedDrawOrder = Math.floor(totalTickets / qty);
        if (updatedDrawOrder > 0) {
          totalTickets -= updatedDrawOrder * qty;
          return pack.set('ordered', updatedDrawOrder);
        }
      }
      return pack;
    });

  const updatedOrder = setOrderContentsForDraw(newOrderedDraws, order, id);

  return dispatch(setOrder(updatedOrder, displaySubscriptionsModal));
};
