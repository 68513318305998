// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Utilities
import { getOrder, getBundleOrders, getTotalPrice } from 'utils/tickets';

// Components
import CurrentOrderHeader from 'buyFlow/components/CurrentOrderHeader';
import CurrentOrderList from 'buyFlow/components/CurrentOrderList';

// Styling
import styles from './styles.module.scss';

const CurrentOrder = React.memo(({ title, order, draws, hideCurrentOrder, location }) => {
  const drawTotals = getOrder(order, draws);
  const bundleTotals = getBundleOrders(order, draws);

  const totalDrawsAndBundles = drawTotals.size + bundleTotals.size;

  const dropdownProps = { title, order, totalDrawsAndBundles };
  const orderListProps = { drawTotals, bundleTotals, order, draws, location };

  return (
    !hideCurrentOrder && (
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <CurrentOrderHeader {...dropdownProps} />
          <CurrentOrderList {...orderListProps} />
          <div className={styles.totalPrice}>
            Total Cost
            <h4 className="theme-color-primary">{`$${getTotalPrice(order)}`}</h4>
          </div>
        </div>
      </div>
    )
  );
});

export default CurrentOrder;

CurrentOrder.propTypes = {
  order: ImmutablePropTypes.list,
  draws: ImmutablePropTypes.list,
  hideCurrentOrder: PropTypes.bool,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  title: PropTypes.string
};

CurrentOrder.defaultProps = {
  order: null,
  draws: null,
  hideCurrentOrder: false,
  title: 'Current Order'
};
