// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import ImmutableProptypes from 'react-immutable-proptypes';
import classnames from 'classnames';

// Utilities
import { generateBundleDescription, generateMaxPackDescription } from 'utils/helpers';
import { isMaxPack } from 'utils/tickets';

// Components
import AddSubtractButton from 'buyFlow/components/AddSubtractButton';

// Styling
import styles from 'buyFlow/components/OrderCard/styles.module.scss';

const BundleOrderCard = ({ pack, updateQuantity }) => {
  const tickets = pack.get('packDrawTypes');
  const orderedPacks = pack.get('ordered');
  const contents = isMaxPack(pack)
    ? generateMaxPackDescription(orderedPacks, tickets)
    : generateBundleDescription(tickets);

  return (
    <div className={styles.orderCard}>
      <AddSubtractButton
        noBackgroundColor
        disableAnimation
        handleAdd={() => updateQuantity(pack.get('code'), 1)}
        handleSubtract={() => updateQuantity(pack.get('code'), -1)}
        controllerClassStyler={controllerStyle => classnames(controllerStyle, styles.buttonStyling)}
        quantity={orderedPacks}
        disabled={!orderedPacks}
      />
      <div
        className={classnames(styles.cardTitle, {
          [styles.boldText]: orderedPacks > 0
        })}
      >
        {pack.get('description')}
      </div>
      {orderedPacks > 0 && (
        <div
          className={classnames(styles.drawOrderBreakdown, {
            [styles.boldText]: orderedPacks > 0
          })}
        >
          <strong>{`${orderedPacks} Pack${orderedPacks === 1 ? '' : 's'} `}</strong>
          <span>{`(${contents})`}</span>
        </div>
      )}
    </div>
  );
};

export default BundleOrderCard;

BundleOrderCard.propTypes = {
  pack: ImmutableProptypes.map.isRequired,
  updateQuantity: PropTypes.func.isRequired
};
