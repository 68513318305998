// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Componenets
import OrderCard from 'buyFlow/components/OrderCard';
import BundleOrderCard from 'buyFlow/components/BundleOrderCard';

// Utilities
import { getBundlePacks } from 'utils/tickets';

// Thunks
import { updateDrawQuantity, updateQuantity } from 'thunks/buyFlow/ordering';

// Styling

export const filterTicketsByDrawId = (drawId, packs) =>
  packs.filter(pack => drawId === pack.get('code'));

const CurrentOrderList = React.memo(props => {
  const { drawTotals, order, draws, location } = props;
  return (
    <div>
      {draws.map(draw => (
        <OrderCard
          key={`d${draw.get('code')}`}
          draw={draw}
          drawTotals={drawTotals}
          updateDrawQuantity={(code, increment) =>
            props.updateDrawQuantity(code, increment, location)
          }
        />
      ))}
      {getBundlePacks(order).map(pack => (
        <BundleOrderCard
          key={`pack${pack.get('code')}`}
          pack={pack}
          updateQuantity={(code, increment) => props.updateQuantity(code, increment, location)}
        />
      ))}
    </div>
  );
});

CurrentOrderList.propTypes = {
  drawTotals: ImmutablePropTypes.list.isRequired,
  updateDrawQuantity: PropTypes.func.isRequired,
  updateQuantity: PropTypes.func.isRequired,
  order: ImmutablePropTypes.list.isRequired,
  draws: ImmutablePropTypes.list.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateDrawQuantity,
      updateQuantity
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(CurrentOrderList);
