// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import Icon from 'common/components/Icon';

// Styling
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const CurrentOrderHeader = React.memo(({ title }) => (
  <div className={cx(styles.currentOrder, 'theme-color-primary')}>
    <div className={styles.order}>
      <div className={cx('theme-color-primary', styles.iconText)}>
        {' '}
        <Icon icon="shopping_cart" />
        {title}
      </div>
    </div>
  </div>
));

CurrentOrderHeader.propTypes = {
  title: PropTypes.string.isRequired
};

export default CurrentOrderHeader;
