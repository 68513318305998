// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Immutable from 'immutable';
import classnames from 'classnames';

// Utils
import { isMonoPack } from 'utils/tickets';
import { ticketStatus } from 'utils/constants';
import { trimCustomizableStr } from 'utils/helpers';

// Components
import AddSubtractButton from 'buyFlow/components/AddSubtractButton';

// Styling
import styles from './styles.module.scss';

const { WAITLISTED, SOLD_OUT } = ticketStatus;

const generateContents = contents =>
  contents.map(contentsText => <li key={contentsText}>{contentsText}</li>);

const TicketQuantity = React.memo(({ ticket, updateQuantity, contents, bestValue }) => {
  const handleAdd = () => updateQuantity(ticket.get('code'), 1);
  const handleSubtract = () => updateQuantity(ticket.get('code'), -1);
  const ticketNumber = ticket.get('ordered');
  const status = ticket.get('status');
  const isMonoPackBool = isMonoPack(ticket);
  const badgeText = !bestValue && trimCustomizableStr(ticket.get('bundleBadgeText'));

  const isBestValue = bestValue && !isMonoPackBool && status !== WAITLISTED && status !== SOLD_OUT;

  const priceTextClass = classnames(styles.price, {
    [styles.price_row]: isMonoPackBool,
    [styles.price_column]: !isMonoPackBool
  });

  const descriptionClass = classnames(styles.description, {
    [styles.description_column]: !isMonoPackBool
  });

  const tagStyles = classnames(
    styles.tag,
    {
      [styles.tag_centered]: !isMonoPackBool
    },
    bestValue ? 'theme-background-tertiary' : {}
  );

  const displayTag = ticketTagStyle => {
    switch (status) {
      case WAITLISTED:
        return <div className={ticketTagStyle}>Wait Listed</div>;
      case SOLD_OUT:
        return badgeText && <div className={ticketTagStyle}>{badgeText}</div>;
      default:
        return badgeText && <div className={ticketTagStyle}>{badgeText}</div>;
    }
  };

  const addSubtractButton = (
    <AddSubtractButton
      quantity={ticket.get('ordered')}
      handleAdd={handleAdd}
      handleSubtract={handleSubtract}
      disabled={ticketNumber === 0}
      showSoldOut={status === SOLD_OUT}
      showVeil={!ticket.get('ordered') && status !== SOLD_OUT}
      buttonWrapperStyler={buttonWrapperStyle =>
        classnames(buttonWrapperStyle, {
          [styles.buttonWrapper_column]: !isMonoPackBool
        })
      }
      controllerClassStyler={controllerStyle =>
        classnames(controllerStyle, {
          [styles.hiddenControl]: ticket.get('ordered') === 0
        })
      }
    />
  );

  const monoPacks = (
    <div className={styles.ticketContainerMono}>
      {displayTag(styles.tag)}

      <div className={styles.textWrapper}>
        <div className={styles.description}>{ticket.get('description')}</div>
        <div className={priceTextClass}>{`$${ticket.get('price')}`}</div>
      </div>

      {addSubtractButton}
    </div>
  );

  const nonMonoPacks = (
    <div className={styles.ticketContainerPack}>
      {displayTag(tagStyles)}
      {isBestValue && <div className={tagStyles}> Best Value </div>}
      <div className={descriptionClass}>
        <h4>{ticket.get('description')}</h4>
      </div>
      {contents && <ul className={styles.contents}>{generateContents(contents)}</ul>}
      <div className={priceTextClass}>{`$${ticket.get('price')}`}</div>

      {addSubtractButton}
    </div>
  );

  return isMonoPackBool ? monoPacks : nonMonoPacks;
});

TicketQuantity.propTypes = {
  ticket: ImmutablePropTypes.map.isRequired,
  contents: ImmutablePropTypes.list,
  updateQuantity: PropTypes.func.isRequired,
  bestValue: PropTypes.oneOfType([ImmutablePropTypes.map, PropTypes.bool])
};

TicketQuantity.defaultProps = {
  contents: Immutable.List([]),
  bestValue: null
};

export default TicketQuantity;
