// Libraries
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import Icon from 'common/components/Icon';
import Subscriptions from 'buyFlow/components/Subscriptions';

// Styles
import styles from './styles.module.scss';

class MembershipAlert extends PureComponent {
  showSubscriptionModal = e => {
    const { onClose, toggleSubscriptionsModal, addAlert } = this.props;

    e.stopPropagation();
    onClose();
    toggleSubscriptionsModal();
    addAlert({
      text: 'SubscriptionsModal',
      alertComponent: props => <Subscriptions {...props} />
    });
  };

  handleCheckout = e => {
    e.stopPropagation();
    this.props.onClose();
    navigate(`/buy-flow/confirm-order`);
  };

  render() {
    return (
      <div className={styles.container}>
        <Icon className={`${styles.icon} theme-color-tertiary`} icon="stars" />
        <p className={`h5 theme-color-primary ${styles.heading}`}>Never Miss a Draw!</p>
        <button
          type="button"
          onClick={this.showSubscriptionModal}
          className={`button button--buyflow theme-background-secondary ${styles.button}`}
        >
          Yes, Become A Member
        </button>
        <button
          type="button"
          onClick={this.handleCheckout}
          className={`button button--buyflow button--inverted theme-border-secondary ${
            styles.button
          }`}
        >
          No Thanks, Complete Checkout
        </button>
      </div>
    );
  }
}

MembershipAlert.propTypes = {
  addAlert: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  toggleSubscriptionsModal: PropTypes.func.isRequired
};

export default MembershipAlert;
