// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect, Field as FormikField } from 'formik';

// Utilities
import { byString } from 'utils/helpers';

// Styling
import styles from './styles.module.css';

const cx = classNames.bind(styles);

// Constants
const MARKETING_ATTRIBUTE_LIST = 'L';
const SELECT = 'select';

const Field = ({
  name,
  label,
  type,
  isRequired,
  placeholder,
  render,
  disabled,
  uppercase,
  children,
  component,
  formik: { errors, touched, handleChange },
}) => {
  const error = byString(errors, name);
  const isTouched = byString(touched, name);
  const hasError = error && isTouched;

  return (
    <>
      <label htmlFor={name} className={cx({ asterisk: isRequired })}>
        {label}
      </label>
      <div
        className={
          type === MARKETING_ATTRIBUTE_LIST || type === SELECT ? styles.selectWrapper : ''
        }
      >
        <FormikField
          type={type}
          name={name}
          placeholder={placeholder}
          component={component}
          className={cx({ invalidInput: hasError })}
          render={render}
          disabled={disabled}
          onChange={(e) => {
            const event = e;
            if (uppercase) event.target.value = e.target.value.toUpperCase();
            handleChange(event);
          }}
        >
          {children}
        </FormikField>
      </div>
      {hasError && <div className={styles.error}>{error}</div>}
    </>
  );
};

Field.propTypes = {
  isRequired: PropTypes.bool,
  uppercase: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  // TODO: FormikField should take one of children, render, component...
  component: PropTypes.string,
  children: PropTypes.node,
  render: PropTypes.func,
  // eslint-disable-next-line
  formik: PropTypes.object.isRequired,
};

Field.defaultProps = {
  render: undefined,
  children: undefined,
  uppercase: false,
  disabled: false,
  component: undefined,
  placeholder: '',
  isRequired: false,
};

export default connect(Field);
