// Library
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import TicketButton from 'buyFlow/components/TicketButton';

// Styling
import styles from './styles.module.scss';

const AddSubtractButton = React.memo(props => {
  const {
    quantity,
    handleAdd,
    handleSubtract,
    disabled,
    showSoldOut,
    showVeil,
    buttonWrapperStyler,
    controllerClassStyler,
    disableAnimation,
    noBackgroundColor
  } = props;

  const buttonWrapperClass = buttonWrapperStyler(styles.buttonWrapper);

  const controllerClass = controllerClassStyler(styles.controls);

  const controllerClassStyles = classnames(controllerClass, {
    [styles.disableAnimation]: disableAnimation
  });

  return (
    <div className={buttonWrapperClass}>
      {showSoldOut && <div className={styles.soldOut}>Sold Out</div>}
      {showVeil && (
        <button
          type="button"
          className={`theme-background-secondary ${styles.controllerVeil}`}
          onClick={() => handleAdd()}
        >
          + Add to Cart
        </button>
      )}

      <div className={controllerClassStyles}>
        <TicketButton
          noBackgroundColor={noBackgroundColor}
          onClick={handleSubtract}
          icon="remove"
          disabled={disabled}
          classStyles={styles.ticketButton}
        />
        <div className={styles.controls_quantity}>{quantity}</div>
        <TicketButton
          noBackgroundColor={noBackgroundColor}
          onClick={handleAdd}
          icon="add"
          classStyles={styles.ticketButton}
        />
      </div>
    </div>
  );
});

AddSubtractButton.propTypes = {
  buttonWrapperStyler: PropTypes.func,
  controllerClassStyler: PropTypes.func,
  handleAdd: PropTypes.func,
  handleSubtract: PropTypes.func,
  disabled: PropTypes.bool,
  showVeil: PropTypes.bool,
  showSoldOut: PropTypes.bool,
  quantity: PropTypes.number,
  disableAnimation: PropTypes.bool,
  noBackgroundColor: PropTypes.bool
};

AddSubtractButton.defaultProps = {
  buttonWrapperStyler: style => style,
  controllerClassStyler: style => style,
  handleAdd: () => {},
  handleSubtract: () => {},
  disabled: false,
  showVeil: false,
  showSoldOut: false,
  disableAnimation: false,
  noBackgroundColor: false,
  quantity: 0
};

export default AddSubtractButton;
