// Libraries
import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import Icon from 'common/components/Icon';

// Styling
import styles from './styles.module.scss';

const BuyFlowAlert = ({ isOpen, onClose, text, component, hideAlert }) => (
  <ReactModal
    isOpen={isOpen}
    contentLabel="Order Tickets Modal"
    className={styles.modal}
    overlayClassName={classnames(styles.overlay, {
      [styles.hideAlert]: hideAlert
    })}
  >
    {component ? (
      component()
    ) : (
      <div className={styles.container}>
        <Icon className={`${styles.icon} theme-color-tertiary`} icon="error_outline" />
        <p className={`h5 theme-color-primary ${styles.heading}`}>{text}</p>
        <button
          type="button"
          onClick={onClose}
          className={`button button--buyflow theme-background-secondary ${styles.button}`}
        >
          Close
        </button>
      </div>
    )}
  </ReactModal>
);

BuyFlowAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  text: PropTypes.string,
  component: PropTypes.func,
  hideAlert: PropTypes.bool
};

BuyFlowAlert.defaultProps = {
  component: null,
  text: '',
  hideAlert: false
};

export default BuyFlowAlert;
