// Libraries
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import classnames from 'classnames';

// Utilities
import { trimCustomizableStr } from 'utils/helpers';

// Styles
import styles from './styles.module.scss';

const generateLinkForDescription = ({
  descIndex,
  indexOfCurrentPath,
  pathLength,
  drawSize,
  tagLine,
  desc,
  path
}) =>
  (descIndex > drawSize - 1 && indexOfCurrentPath !== pathLength) ||
  descIndex === indexOfCurrentPath ? (
    displayDescription(tagLine, desc)
  ) : (
    <Link to={path}>{displayDescription(tagLine, desc)}</Link>
  );

generateLinkForDescription.propTypes = {
  descIndex: PropTypes.number.isRequired,
  indexOfCurrentPath: PropTypes.number.isRequired,
  pathLength: PropTypes.number.isRequired,
  drawSize: PropTypes.number.isRequired,
  tagLine: PropTypes.string,
  desc: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired
};

generateLinkForDescription.defaultProps = {
  tagLine: ''
};

const displayDescription = (tagLine, desc) => trimCustomizableStr(tagLine) || desc;

class CartTrail extends PureComponent {
  render() {
    const { currentIndex, pathDescEntries, drawSize } = this.props;

    const indexOfCurrentPath = currentIndex(pathDescEntries);

    const applyLeftLineStyling = (index, style) =>
      classnames(style, {
        [styles.filledLine]: index <= indexOfCurrentPath,
        [styles.roundedCornerRight]: index === pathDescEntries.length - 1
      });

    const applyRightLineStyling = (index, style) =>
      classnames(style, {
        [styles.filledLine]: index !== pathDescEntries.length && index < indexOfCurrentPath,
        [styles.roundedCornerLeft]: index === 0
      });

    const circleStyle = classnames(styles.circle);

    const descriptionStyle = index =>
      classnames(styles.description, {
        [styles.boldDesc]: indexOfCurrentPath === index
      });

    const generateHorizontalBars = pathDescEntries.map(({ desc, path, tagLine }, index) => (
      <div className={styles.locationContainer} key={`cartTrail_${desc}`}>
        <div className={styles.flexColumn}>
          <div className={styles.fill}>
            <div
              className={
                index !== 0
                  ? applyLeftLineStyling(index, styles.horizontalLineLeft)
                  : styles.hiddenLine
              }
            >
              &nbsp;
            </div>
            <div
              className={
                index !== pathDescEntries.length - 1
                  ? applyRightLineStyling(index, styles.horizontalLineRight)
                  : styles.hiddenLine
              }
            >
              &nbsp;
            </div>
            {indexOfCurrentPath === index && <div className={circleStyle}>&nbsp;</div>}
          </div>
          <div className={styles.descWrapper}>
            <div className={descriptionStyle(index)}>
              {generateLinkForDescription({
                descIndex: index,
                indexOfCurrentPath,
                drawSize,
                pathLength: pathDescEntries.length - 1,
                tagLine,
                desc,
                path
              })}
            </div>
          </div>
        </div>
      </div>
    ));

    return (
      <div className={styles.cartTrail}>
        <div className={styles.flexRow}>{generateHorizontalBars}</div>
      </div>
    );
  }
}

CartTrail.propTypes = {
  currentIndex: PropTypes.func.isRequired,
  pathDescEntries: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  drawSize: PropTypes.number.isRequired
};

export default CartTrail;
