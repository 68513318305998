// Libraries
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// Components
import Field from 'buyFlow/components/Field';

// Styling
import styles from './styles.module.css';

const generateFields = fields =>
  fields.map(field => (
    <Fragment key={field.name}>
      {field.selectField || <Field {...field} />}
      {field.description && <p>{field.description}</p>}
    </Fragment>
  ));

const FieldGroup = ({ fields, details, children, fieldGroupStyles }) => (
  <div className={`${styles.fieldGroup} ${fieldGroupStyles}`}>
    {fields && generateFields(fields)}
    {children}
    {details && <p className={styles.fieldDetails}>{details}</p>}
  </div>
);

FieldGroup.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.arrayOf(PropTypes.element),
  details: PropTypes.string,
  fieldGroupStyles: PropTypes.string
};

FieldGroup.defaultProps = {
  details: '',
  fieldGroupStyles: '',
  children: [],
  fields: []
};

export default FieldGroup;
