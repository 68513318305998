import React from 'react';
import PropTypes from 'prop-types';
import { Router } from '@reach/router';
import { Helmet } from 'react-helmet';

// Components
import BuyFlowContainer from 'buyFlow/containers/BuyFlowContainer';
import TicketOrderContainer from 'buyFlow/containers/TicketOrderContainer';
import PurchaseFormContainer from 'buyFlow/containers/PurchaseFormContainer';
import OrderSummaryContainer from 'buyFlow/containers/OrderSummaryContainer';
import PaymentComplete from 'buyFlow/components/PaymentComplete';
import PrivateRoute from 'buyFlow/components/Routes/PrivateRoute';
import Layout from 'buyFlow/components/Layout';
import ScrollToTop from 'buyFlow/components/ScrollToTop';
import RestrictedPage from 'buyFlow/components/RestrictedPage';

// Styles
import { wrapper } from 'buyFlow/containers/BuyFlowContainer/styles.module.scss';

// Data
import data from 'data/data.json';

const BuyFlow = ({ location }) => {
  const {
    buyFlowData: { epStatus, gateways, hasMaxPack },
    includeMembershipSubscriptions,
    appearance: { programLogo } = {},
    rulesPdf,
    privacyPolicyPdf,
    contactUsPage: { details } = {},
    winnersPage
  } = data.raffle;

  if (winnersPage && winnersPage.winnersMode) {
    return <RestrictedPage />;
  }

  const footerLinks = { rulesPdf, privacyPolicyPdf, details };

  const BuyFlowRoute = React.memo(() => (
    <BuyFlowContainer
      gateways={gateways}
      statusEndpoint={epStatus}
      programLogo={programLogo}
      footerLinks={footerLinks}
      hasMaxPack={hasMaxPack}
      location={location}
      includeMembershipSubscriptions={includeMembershipSubscriptions}
      render={props => (
        <Router primary={false}>
          <TicketOrderContainer path="/*" {...props} />
          <PrivateRoute
            {...props}
            path="/purchase-order"
            component={() => <PurchaseFormContainer {...props} />}
          />
          <PrivateRoute
            {...props}
            path="/confirm-order"
            component={() => <OrderSummaryContainer {...props} />}
          />
          <PrivateRoute
            {...props}
            path="/payment-complete"
            component={() => <PaymentComplete {...props} />}
          />
        </Router>
      )}
    />
  ));

  return (
    <Layout wrapperStyle={wrapper}>
      <Helmet>
        <script src="//js.maxmind.com/js/apis/geoip2/v2.1/geoip2.js" type="text/javascript" />
      </Helmet>
      <ScrollToTop location={location}>
        <Router primary={false}>
          <BuyFlowRoute path="buy-flow/*" />
        </Router>
      </ScrollToTop>
    </Layout>
  );
};

BuyFlow.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired
};

export default BuyFlow;
