// Libraries
import React from 'react';

const RestrictedPage = () => (
  <div>
    <h1 className="theme-color-tertiary">404</h1>
    <br />
    <h3 className="theme-color-tertiary">Oops! This Page Could Not Be Found</h3>
    <br />
    <h6>
      SORRY BUT THE PAGE YOU ARE LOOKING FOR DOES NOT EXIST, HAS BEEN REMOVED, OR IS TEMPORARILY
      UNAVAILABLE.
    </h6>
  </div>
);

export default RestrictedPage;
