// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Utilities
import { attribType } from 'utils/attributes';

// Components
import Field from 'buyFlow/components/Field';
import CheckboxField from 'buyFlow/components/CheckboxField';

const generateOptions = listWithEmptyOption =>
  listWithEmptyOption.map(option => {
    // TODO: confirm "display" field is now named "value" with Elmar
    const display = option.display || option.value;
    return (
      <option key={`${[display, option.hidden].join('-')}`} value={option.hidden || display}>
        {display}
      </option>
    );
  });

const MarketingAttribute = ({ attribute }) => {
  const { list, datatype, code, label } = attribute;
  const listWithEmptyOption = list && [{ value: undefined }, ...list];

  switch (datatype) {
    case attribType.TEXT:
      return (
        <div className="fieldGroup">
          <Field name={`attrib-${code}`} type={datatype} label={label} />
        </div>
      );
    case attribType.LIST:
      return (
        <div className="fieldGroup">
          <Field name={`attrib-${code}`} type={datatype} label={label} component="select">
            {generateOptions(listWithEmptyOption)}
          </Field>
        </div>
      );
    case attribType.NUMBER:
      return (
        <div className="fieldGroup">
          <Field name={code} type={code} label={label} />
        </div>
      );
    case attribType.INTEGER:
      return (
        <div className="fieldGroup">
          <Field name={code} type={code} label={label} />
        </div>
      );
    case attribType.BOOL:
      return (
        <div className="fieldGroup">
          <CheckboxField name={`attrib-${code}`} description={label} />
        </div>
      );
    default:
      return (
        <div className="fieldGroup">
          <Field name={`attrib-${code}`} type={attribType.TEXT} label={label} />
        </div>
      );
  }
};

MarketingAttribute.propTypes = {
  attribute: PropTypes.shape({
    list: PropTypes.array,
    dataType: PropTypes.string,
    code: PropTypes.number,
    label: PropTypes.string
  }).isRequired
};

export default MarketingAttribute;
