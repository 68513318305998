// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Utilities
import { generateBestDealText } from 'utils/helpers';

// Components
import Icon from 'common/components/Icon';

// Styling
import classNames from 'classnames/bind';
import styles from './styles.module.css';

const cx = classNames.bind(styles);

const BestDeal = React.memo(({ text, bonusTickets, priceSavings, onSetOrder }) => {
  const hasBetterDeal = bonusTickets > 0 || priceSavings > 0;

  return (
    <div className={cx(styles.container, { noDeal: !hasBetterDeal })}>
      <div className={styles.text}>
        <Icon icon="info_outline" color="#4a90e2" />
        <div>
          <span>{generateBestDealText(bonusTickets, priceSavings)}</span>
          {text}
          <p>
            <button className={styles.applyOptimization} type="button" onClick={onSetOrder}>
              Apply to Order
            </button>
          </p>
        </div>
      </div>
    </div>
  );
});

BestDeal.propTypes = {
  text: PropTypes.string.isRequired,
  bonusTickets: PropTypes.number.isRequired,
  priceSavings: PropTypes.number.isRequired,
  onSetOrder: PropTypes.func.isRequired
};

export default BestDeal;
