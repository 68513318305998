// Libraries
import { fromJS } from 'immutable';
import { navigate } from '@reach/router';

// Data
import { raffle } from 'data/data.json';

// Utilities
import { post, errors, extractAuthnetKeys } from 'utils/api';
import { createSoalaPayment, createConfirmOrder } from 'utils/tickets';

// Actions
import { toggleLoading } from 'ducks/loading/actions';
import { setError } from 'ducks/error/actions';
import {
  setOrderSuccess,
  setPaymentEndpoint,
  setTransactionId,
  setVerifiedOrder
} from 'ducks/ordering/actions';

const SUCCESS = 200;
const OK = 'O';

export const sendPaymentToSoala = values => async (dispatch, getState) => {
  try {
    const { nonce, descriptor, verifiedOrder, maskedPan, expMm, expYy } = values;

    const {
      orderingState: { order, purchaserInfo },
      credentialsState: { jwt }
    } = getState();

    const soalaOrder = createSoalaPayment({
      order,
      purchaser: purchaserInfo,
      jwt,
      nonce,
      descriptor,
      verifiedOrder,
      maskedPan,
      expMm,
      expYy
    });

    dispatch(toggleLoading());

    const allPaymentsSuccess = payments => payments.every(payment => payment.result === OK);
    // TODO: remove payment url override
    const res = await post(verifiedOrder.get('epPayment'), soalaOrder);

    if (res.status === SUCCESS && allPaymentsSuccess(res.data.payments)) {
      dispatch(setOrderSuccess(true));
      navigate('/buy-flow/payment-complete');
    } else {
      dispatch(setError(errors.PAYMENT));
    }
    dispatch(toggleLoading());
  } catch (err) {
    dispatch(setError(errors.PAYMENT));
    throw err;
  }
};

export const confirmOrder = () => async (dispatch, getState) => {
  try {
    const {
      buyFlowData: { gateways }
    } = raffle;

    const {
      orderingState: {
        order,
        purchaserInfo,
        transactionId,
        attributes,
        orderEndpoint,
        subscriptionsAddedToOrder,
        subscriptions
      },
      credentialsState: { jwt }
    } = getState();

    const subs = subscriptionsAddedToOrder ? { subscriptions } : {};

    const confirmOrderPayload = createConfirmOrder({
      purchaserInfo,
      jwt,
      order,
      gateways,
      transactionId,
      attributes,
      ...subs
    });

    const EXPIRED_SESSION_CODE = 1005;
    const CONFIRM_CODE = 201;

    dispatch(toggleLoading());

    const { data, status } = await post(orderEndpoint, confirmOrderPayload);

    if (status === CONFIRM_CODE) {
      dispatch(toggleLoading());
      const immutableData = fromJS(data);
      dispatch(setPaymentEndpoint(immutableData.get('epPayment')));
      dispatch(setTransactionId(immutableData.get('trxId')));
      dispatch(setVerifiedOrder(extractAuthnetKeys(immutableData)));
    } else if (data.errors && data.errors.some(({ code }) => code === EXPIRED_SESSION_CODE)) {
      dispatch(setError(errors.SESSION));
    } else {
      dispatch(setError(errors.VERIFY));
    }
  } catch (err) {
    dispatch(setError(errors.VERIFY));
    throw err;
  }
};
