// Libraries
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import { Router, navigate } from '@reach/router';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Utilities
import {
  getPacksByDrawId,
  optimizeOrderForPacks,
  getTotalPrice,
  getTotalTickets,
  getMonoPacks,
  displayDrawQuantities,
  setOrderContentsForDraw,
  extractContentsText
} from 'utils/tickets';

// Components
import Loading from 'buyFlow/components/Loading';
import TicketQuantity from 'buyFlow/components/TicketQuantity';
import TicketOrder from 'buyFlow/components/TicketOrder';

// State
import { calculateBestValuePacks } from 'ducks/ordering/selectors';

// Thunks
import { getSequential, updateQuantity } from 'thunks/buyFlow/ordering';

const DRAWPATHLENGTH = 18;

const getTitleFromDraws = (path, draws) => {
  if (path === '/buy-flow' || path === '/buy-flow/') {
    return draws.getIn([0, 'description']);
  }
  return draws.getIn([path[DRAWPATHLENGTH], 'description']);
};

class TicketOrderContainer extends PureComponent {
  componentDidMount() {
    const { statusEndpoint, jwt, draws, location, setTitle, hasMaxPack } = this.props;
    setTitle(getTitleFromDraws(location.pathname, draws));

    const urls = new Array(...statusEndpoint);
    return !jwt && this.props.getSequential(urls, hasMaxPack);
  }

  extractBestDealInfo = tickets => {
    const monoPacks = getMonoPacks(tickets).filter(p => p.get('status') !== 'S');
    const totalPrice = getTotalPrice(monoPacks);
    const totalTickets = getTotalTickets(monoPacks);
    const optimizedOrder = optimizeOrderForPacks(monoPacks, totalPrice);
    const optimizedTotalPrice = getTotalPrice(optimizedOrder);
    const optimizedTotalTickets = getTotalTickets(optimizedOrder);
    const bonusTickets = optimizedTotalTickets - totalTickets;
    const priceSavings = totalPrice - optimizedTotalPrice;

    return fromJS({
      optimizedOrder,
      bonusTickets,
      priceSavings,
      dealText: `Order ${displayDrawQuantities(optimizedOrder)} 
        instead of ${displayDrawQuantities(monoPacks)}.`
    });
  };

  onNextDraw = drawCode => {
    navigate(`/buy-flow/tickets-${drawCode + 1}`);
  };

  handleNextButton = drawCode => {
    const { draws, onNext, location } = this.props;
    return location.pathname !== `/buy-flow/tickets-${draws.size - 1}`
      ? this.onNextDraw(drawCode)
      : onNext();
  };

  onSetOrder = (newOrder, drawCode) => {
    const { order, setOrder, draws } = this.props;

    const drawId = draws.getIn([drawCode, 'code']);
    const result = setOrderContentsForDraw(newOrder, order, drawId);

    setOrder(result);
  };

  generateTicketQuantity = tickets =>
    tickets.map(ticket => {
      const { bestValuePacks } = this.props;

      const bestValue =
        bestValuePacks &&
        bestValuePacks.size > 0 &&
        bestValuePacks.find(pack => pack.get('code') === ticket.get('code'));

      return (
        <TicketQuantity
          key={ticket.get('code')}
          ticket={ticket}
          updateQuantity={(code, increment) =>
            this.props.updateQuantity(code, increment, this.props.location)
          }
          bestValue={bestValue}
          contents={extractContentsText(ticket)}
        />
      );
    });

  render() {
    const { draws, order, onBack, location, renderComponent } = this.props;

    if (!renderComponent) {
      return <Loading text="Retrieving Ticket Information..." />;
    }

    return (
      <Router>
        {draws.map(draw => {
          const drawCode = draw.get('code') - 1;
          const drawId = draws.getIn([drawCode, 'code']);
          const tickets = getPacksByDrawId(drawId, order);
          const bestDeal = this.extractBestDealInfo(tickets);
          return (
            <TicketOrder
              key={`ticketorder_${drawCode}`}
              path={`/${drawCode === 0 ? '/' : `tickets-${drawCode}`}`}
              generatedTickets={this.generateTicketQuantity(tickets)}
              bestDeal={bestDeal}
              onSetOrder={this.onSetOrder}
              handleNextButton={this.handleNextButton}
              handleBackButton={onBack}
              location={location}
              drawCode={drawCode}
            />
          );
        })}
      </Router>
    );
  }
}

TicketOrderContainer.propTypes = {
  order: ImmutablePropTypes.list.isRequired,
  draws: ImmutablePropTypes.list.isRequired,
  bestValuePacks: ImmutablePropTypes.list,
  statusEndpoint: PropTypes.arrayOf(PropTypes.string).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  hasMaxPack: PropTypes.bool,
  jwt: PropTypes.string,
  updateQuantity: PropTypes.func.isRequired,
  getSequential: PropTypes.func.isRequired,
  setOrder: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  renderComponent: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

TicketOrderContainer.defaultProps = {
  jwt: '',
  hasMaxPack: false,
  bestValuePacks: null,
  renderComponent: false
};

const mapStateToProps = ({ orderingState }) => ({
  bestValuePacks: calculateBestValuePacks(orderingState)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateQuantity,
      getSequential
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketOrderContainer);
