// Libraries
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { fromJS } from 'immutable';
import * as Sentry from '@sentry/browser';
import { navigate } from '@reach/router';

// Utilities
import { errors } from 'utils/api';

// Data
import { raffle } from 'data/data.json';

// Components
import ErrorPage from 'buyFlow/components/ErrorPage';

// Styles
import styles from './styles.module.scss';

class ErrorContainer extends PureComponent {
  state = { error: null, eventId: null };

  onTryAgain = () => {
    const { setDrawStage, error, setError } = this.props;

    const resetPages = () => {
      setError('');
      setDrawStage(0);
    };

    if (error === errors.SESSION) {
      this.reinitializeBuyFlow();
      navigate('/buy-flow');
    } else {
      resetPages();
      navigate('/buy-flow/confirm-order');
    }
  };

  reinitializeBuyFlow = () => {
    const {
      setOrder,
      setPurchaserInfo,
      setJwt,
      setTransactionId,
      setError,
      setDrawStage
    } = this.props;
    const { packTypes } = raffle;
    const initialOrderState = fromJS(packTypes).map(pack => pack.set('ordered', 0));

    setTransactionId('');
    setOrder(initialOrderState);
    setPurchaserInfo(undefined);
    setJwt('');
    setError('');
    setDrawStage(0);
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
    });
    const eventId = Sentry.captureException(error);
    this.setState({ eventId });
  }

  render() {
    const { children, location } = this.props;

    if (this.props.error && location) {
      return <ErrorPage {...this.props.error} reinitializeBuyFlow={this.reinitializeBuyFlow} />;
    }

    if (this.state.error) {
      return (
        <div className={styles.errorContainer}>
          <h5 className={styles.errorContainer_text}>
            {`It looks like we're having issues. Our team has been notified. 
              If you'd like to help, tell us what happened by clicking on the button below`}
          </h5>
          <button
            className={`button button--buyflow theme-background-primary ${
              styles.errorContainer_button
            }`}
            type="button"
            onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}
          >
            Report Feedback
          </button>
        </div>
      );
    }

    return children;
  }
}

ErrorContainer.propTypes = {
  setOrder: PropTypes.func.isRequired,
  setPurchaserInfo: PropTypes.func.isRequired,
  setDrawStage: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setJwt: PropTypes.func.isRequired,
  setTransactionId: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  error: PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.string]),
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired
};

ErrorContainer.defaultProps = {
  error: null
};

export default ErrorContainer;
