// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

// Utilities
import { trimCustomizableStr } from 'utils/helpers';

// Components
import Icon from 'common/components/Icon';

// Styling
import styles from './styles.module.scss';

class LotteryBanner extends React.Component {
  componentDidMount() {
    const headerWrapperController = document.querySelector('#lotteryHeaderWrapper');
    const headerController = document.querySelector('#lotteryHeader');

    window.onscroll = () => {
      const posY = window.pageYOffset;
      if (posY > 0) {
        headerWrapperController.classList.add(styles.borderBottom);
        headerController.classList.add(styles.borderBottom);
        return;
      }
      headerWrapperController.classList.remove(styles.borderBottom);
      headerController.classList.remove(styles.borderBottom);
    };
  }

  render() {
    const { getTitle, programLogo, totalPrice } = this.props;
    const { desc, tagLine } = getTitle;
    return (
      <>
        <div id="lotteryHeaderWrapper" className={styles.fixedHeader}>
          <div id="lotteryHeader" className={styles.orderTickets}>
            <span className={styles.orderTickets_iconText}>
              <h5>
                <Icon
                  icon="local_activity"
                  className={`${styles.orderTickets_icon} theme-color-primary`}
                />
              </h5>
              <h5 className={`${styles.orderTickets_orderText} theme-color-primary`}>
                Order Tickets
              </h5>
            </span>
            <span className={styles.orderTickets_price}>
              Total Cost
              <h4 className="theme-color-primary">{`$${totalPrice}`}</h4>
            </span>
          </div>
        </div>
        <div className={styles.bannerContainer}>
          <div className={`theme-background-primary ${styles.banner}`}>
            <div className={styles.wrapper}>
              <Link to="/" className={styles.logo}>
                <img className={styles.logoImage} src={programLogo} alt=" " />
              </Link>
              <div className={styles.title}>
                <p>{trimCustomizableStr(tagLine) || desc}</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

LotteryBanner.propTypes = {
  getTitle: PropTypes.shape({ desc: PropTypes.string, tagLine: PropTypes.string }).isRequired,
  programLogo: PropTypes.string,
  totalPrice: PropTypes.number
};

LotteryBanner.defaultProps = {
  programLogo: 'http://via.placeholder.com/142x103',
  totalPrice: 0
};

export default LotteryBanner;
