// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';

// Utilities
import { displayDrawQuantities } from 'utils/tickets';

// Components
import AddSubtractButton from '../AddSubtractButton';

// Styling
import styles from './styles.module.scss';

const OrderCard = React.memo(({ draw, updateDrawQuantity, drawTotals }) => {
  const orderedDraw =
    drawTotals &&
    drawTotals.size &&
    drawTotals.find(ordDraw => ordDraw.get('code') === draw.get('code'));

  const totalTickets = orderedDraw && orderedDraw.get('totalTickets');

  return (
    <div className={styles.orderCard}>
      <AddSubtractButton
        noBackgroundColor
        disableAnimation
        handleAdd={() => updateDrawQuantity(draw.get('code'), 1)}
        handleSubtract={() => updateDrawQuantity(draw.get('code'), -1)}
        quantity={totalTickets}
        disabled={!totalTickets}
        controllerClassStyler={controllerStyle => classnames(controllerStyle, styles.buttonStyling)}
      />
      <div
        className={classnames(styles.cardTitle, {
          [styles.boldText]: totalTickets > 0
        })}
      >
        {draw.get('description')}
      </div>
      {totalTickets > 0 && (
        <div
          className={classnames(styles.drawOrderBreakdown, {
            [styles.boldText]: totalTickets > 0
          })}
        >
          <span>{`(${displayDrawQuantities(orderedDraw.get('packs'), true)})`}</span>
        </div>
      )}
    </div>
  );
});

OrderCard.propTypes = {
  draw: ImmutablePropTypes.map.isRequired,
  updateDrawQuantity: PropTypes.func.isRequired,
  drawTotals: ImmutablePropTypes.list.isRequired
};

export default OrderCard;
