// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Icon from 'common/components/Icon';

// Styling
import styles from './styles.module.scss';

const WaitlistAlert = ({ onAccept, handleBackButton }) => (
  <div className={`buyflow-form ${styles.container}`}>
    <Icon icon="error_outline" color="#4a90e2" className={styles.icon} />
    <p className={`h4 theme-color-primary ${styles.text}`}>Your order contains waitlisted items</p>
    <p className="buyflow-form__text u-text-align-center">
      Waitlisted items will be processed in the order received.
    </p>
    <p className="buyflow-form__text u-text-align-center">
      Your card will not be charged unless tickets are available at the time of your order.
    </p>
    <button
      type="button"
      className="button button--buyflow theme-background-secondary"
      onClick={onAccept}
    >
      I Understand
    </button>
    <div className={styles.buttonWrapper}>
      <button
        type="button"
        className="button button--buyflow button--inverted theme-border-secondary"
        onClick={handleBackButton}
      >
        Go Back
      </button>
    </div>
  </div>
);

WaitlistAlert.propTypes = {
  onAccept: PropTypes.func.isRequired,
  handleBackButton: PropTypes.func.isRequired
};

export default WaitlistAlert;
