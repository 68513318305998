// Libraries
import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Components
import Loading from 'buyFlow/components/Loading';
import WaitlistAlert from 'buyFlow/components/WaitlistAlert';
import SummaryData from './SummaryData';

// Styling
import styles from './styles.module.scss';

class OrderSummary extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      waitlistAccepted: false
    };

    this.dataValue = React.createRef();
    this.dataDescriptor = React.createRef();
    this.maskedPan = React.createRef();
    this.expMm = React.createRef();
    this.expYy = React.createRef();
  }

  componentDidMount() {
    window.responseHandler = this.responseHandler;
    this.props.confirmOrder();
  }

  responseHandler = response =>
    response.messages.resultCode === 'Error'
      ? this.paymentFormError(response.messages.message)
      : this.paymentFormUpdate(response);

  paymentFormError = message => {
    const result = message.reduce((acc, msg) => {
      const formattedMessage = `${msg.code}: ${msg.text}`;
      acc.push(formattedMessage);
      return acc;
    }, []);

    document.getElementById('authorizeErrors').innerHTML = result.join(', ');
  };

  paymentFormUpdate = response => {
    const paymentData = response.opaqueData;
    const cardData = response.encryptedCardData;
    const cardNumber = cardData.cardNumber.slice(
      cardData.cardNumber.length - 4,
      cardData.cardNumber.length
    );

    const { expDate } = cardData;

    document.getElementById('dataDescriptor').value = paymentData.dataDescriptor;
    document.getElementById('dataValue').value = paymentData.dataValue;
    document.getElementById('maskedPan').value = [cardData.bin, '******', cardNumber].join('');
    document.getElementById('expMm').value = expDate.slice(0, 2);
    document.getElementById('expYy').value = expDate.slice(expDate.length - 2, expDate.length);

    document.getElementById('finishButton').click();
  };

  handleSubmit = event => {
    const { onSubmit, verifiedOrder, paymentEndpoint } = this.props;
    event.preventDefault();
    // TODO: support separate maskedPan/expMm/expYY for multiple payments post-mvp
    onSubmit({
      nonce: this.dataValue.current.value,
      descriptor: this.dataDescriptor.current.value,
      maskedPan: this.maskedPan.current.value,
      expMm: this.expMm.current.value,
      expYy: this.expYy.current.value,
      verifiedOrder,
      paymentEndpoint
    });
  };

  printNames = ticketNames =>
    ticketNames.map(name => <div>{`${name.first_name} ${name.last_name}`}</div>);

  onAcceptWaitlist = () => this.setState({ waitlistAccepted: true });

  render() {
    const {
      purchaserInfo,
      verifiedOrder,
      isLoading,
      order,
      handleBackButton,
      isWaitlisted,
      subscriptionsAddedToOrder
    } = this.props;

    const { waitlistAccepted } = this.state;

    if (!verifiedOrder || isLoading) {
      return <Loading text="Verifying Order..." />;
    }
    if (!waitlistAccepted && isWaitlisted) {
      return <WaitlistAlert onAccept={this.onAcceptWaitlist} handleBackButton={handleBackButton} />;
    }

    return (
      <div className={styles.container}>
        <Helmet>
          <script type="text/javascript" src={verifiedOrder.get('url')} charset="utf-8" />
        </Helmet>

        <SummaryData
          order={order}
          subscriptionsAddedToOrder={subscriptionsAddedToOrder}
          purchaserInfo={purchaserInfo}
          printNames={this.printNames}
        />

        <form id="paymentForm" onSubmit={this.handleSubmit}>
          <input type="hidden" name="dataValue" id="dataValue" ref={this.dataValue} />
          <input
            type="hidden"
            name="dataDescriptor"
            id="dataDescriptor"
            ref={this.dataDescriptor}
          />
          <input type="hidden" name="maskedPan" id="maskedPan" ref={this.maskedPan} />
          <input type="hidden" name="expMm" id="expMm" ref={this.expMm} />
          <input type="hidden" name="expYy" id="expYy" ref={this.expYy} />
          <button
            type="button"
            className={`AcceptUI button button--buyflow button--dark theme-background-secondary ${
              styles.payButton
            }`}
            data-billingAddressOptions='{"show":false, "required":false}'
            data-apiLoginId={verifiedOrder.getIn(['params', 'login'])}
            data-clientKey={verifiedOrder.getIn(['params', 'key'])}
            data-acceptUIFormBtnTxt="Submit"
            data-acceptUIFormHeaderTxt="Card Information"
            data-responseHandler="responseHandler"
          >
            Pay for Order
          </button>
          <button id="finishButton" type="button" hidden onClick={this.handleSubmit} />
          <div id="authorizeErrors" className={styles.error} />
        </form>
      </div>
    );
  }
}

OrderSummary.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  purchaserInfo: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    homePhone: PropTypes.string,
    lastName: PropTypes.string,
    postalCode: PropTypes.string,
    province: PropTypes.string
  }).isRequired,
  confirmOrder: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  paymentEndpoint: PropTypes.string.isRequired,
  verifiedOrder: ImmutablePropTypes.map,
  isWaitlisted: PropTypes.bool.isRequired,
  order: ImmutablePropTypes.list.isRequired,
  handleBackButton: PropTypes.func.isRequired,
  subscriptionsAddedToOrder: PropTypes.bool
};

OrderSummary.defaultProps = {
  verifiedOrder: null,
  subscriptionsAddedToOrder: false
};

export default OrderSummary;
