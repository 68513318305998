// Libraries
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Utilities
import { hasOrderedWaitlistedPacks } from 'utils/tickets';

// Thunks
import { confirmOrder, sendPaymentToSoala } from 'thunks/buyFlow/payment';

// Components
import OrderSummary from 'buyFlow/components/OrderSummary';

class OrderSummaryContainer extends PureComponent {
  componentWillUnmount() {
    this.props.addSubscriptionsToOrder(false);
  }

  render() {
    const {
      order,
      purchaserInfo,
      verifiedOrder,
      isLoading,
      orderEndpoint,
      waitlistEnabled,
      subscriptionsAddedToOrder,
      onBack
    } = this.props;

    return (
      <OrderSummary
        onSubmit={this.props.sendPaymentToSoala}
        purchaserInfo={purchaserInfo}
        orderEndpoint={orderEndpoint}
        confirmOrder={this.props.confirmOrder}
        order={order}
        verifiedOrder={verifiedOrder}
        isLoading={isLoading}
        subscriptionsAddedToOrder={subscriptionsAddedToOrder}
        isWaitlisted={hasOrderedWaitlistedPacks(order) && waitlistEnabled}
        handleBackButton={onBack}
      />
    );
  }
}

OrderSummaryContainer.propTypes = {
  orderEndpoint: PropTypes.string.isRequired,
  confirmOrder: PropTypes.func.isRequired,
  sendPaymentToSoala: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  waitlistEnabled: PropTypes.bool.isRequired,
  onBack: PropTypes.func.isRequired,
  order: ImmutablePropTypes.list.isRequired,
  purchaserInfo: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    homePhone: PropTypes.string,
    lastName: PropTypes.string,
    postalCode: PropTypes.string,
    province: PropTypes.string
  }).isRequired,
  verifiedOrder: ImmutablePropTypes.map,
  addSubscriptionsToOrder: PropTypes.func.isRequired,
  subscriptionsAddedToOrder: PropTypes.bool
};

OrderSummaryContainer.defaultProps = {
  verifiedOrder: null,
  subscriptionsAddedToOrder: false
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      sendPaymentToSoala,
      confirmOrder
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(OrderSummaryContainer);
