import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.module.css';

const cx = classNames.bind(styles);

// TODO: convert to button
const Icon = React.memo(({ icon, size, color, className, onClick }) => (
  <div
    onClick={() => {}}
    role="button"
    tabIndex={0}
    onKeyDown={onClick}
    style={{ color }}
    className={cx(styles.materialIcons, size, className)}
  >
    {icon}
  </div>
));

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};

Icon.defaultProps = {
  size: 'medium',
  className: '',
  color: '',
  onClick: () => {}
};

export default Icon;
