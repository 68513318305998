// Libraries
import React from 'react';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Utilities
import { errors } from 'utils/api';

// Styling
import iconWarning from 'images/icon-warning.svg';
import styles from './styles.module.css';

const renderMessage = message =>
  message.map(text => (
    <p key={text} className={classnames(styles.errorText, 'buyflow-form__text')}>
      {text}
    </p>
  ));

const ErrorPage = ({ title, message }) => (
  <div className={`buyflow-form ${styles.container}`}>
    <img className="buyflow-form__icon u-text-align-center" src={iconWarning} alt="Warning Icon" />
    {title && <p className={`h4 theme-color-primary ${styles.text}`}>{title}</p>}
    {message && renderMessage(message)}
    <div>
      <Link to="/" className="button button--link">
        Cancel Order
      </Link>
    </div>
  </div>
);

ErrorPage.propTypes = {
  title: PropTypes.string,
  message: PropTypes.arrayOf(PropTypes.String)
};

ErrorPage.defaultProps = {
  title: errors.STATUS.title,
  message: [errors.STATUS.message]
};

export default ErrorPage;
