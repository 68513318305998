// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Utilities
import {
  formatPhoneNumber,
  generateBundleDescription,
  generateMaxPackDescription
} from 'utils/helpers';
import { getMonoPacks, getBundlePacks, isOrdered, isMaxPack, getTotalPrice } from 'utils/tickets';

// Styles
import styles from './styles.module.scss';

const SummaryData = props => {
  const {
    purchaserInfo: {
      firstName,
      lastName,
      address,
      city,
      province,
      postalCode,
      country,
      email,
      homePhone,
      mobilePhone,
      printPurchaserName,
      ticketNames
    },
    order,
    subscriptionsAddedToOrder,
    printNames
  } = props;

  const monoPacks = getMonoPacks(order).filter(isOrdered);
  const bundlePacks = getBundlePacks(order).filter(isOrdered);

  const displayDrawPackInformation = pack => (
    <div className={styles.summaryContainer_mono} key={`${pack.get('code')}_drawInfo`}>
      <div className={styles.summaryContainer_desc}>
        {`${pack.get('description')} x${pack.get('ordered')}`}
      </div>
      <div>{`$${pack.get('price') * pack.get('ordered')}`}</div>
    </div>
  );

  const displayBundlePackInformation = pack => {
    const tickets = pack.get('packDrawTypes');
    const contents = isMaxPack(pack)
      ? generateMaxPackDescription(bundlePacks, tickets)
      : generateBundleDescription(tickets);

    return (
      <div
        className={styles.summaryContainer_bundleContainer}
        key={`${pack.get('code')}_bundleInfo`}
      >
        <div className={styles.summaryContainer_bundle}>
          <div className={styles.summaryContainer_desc}>
            {`${pack.get('description')} x${pack.get('ordered')}`}
          </div>
          <div>{`$${pack.get('price')}`}</div>
        </div>
        <div>{contents}</div>
      </div>
    );
  };

  return (
    <>
      <h4 className={`${styles.boldText} theme-color-primary`}>Summary </h4>

      <div className={styles.summaryDetails}>
        Tickets will now be in your hands faster than ever via email delivery. Please allow 3 - 4
        business days.
      </div>

      <h5 className={styles.ticketRequestText}>Offical Ticket Request</h5>

      <div className={styles.summaryContainer}>
        {subscriptionsAddedToOrder && (
          <div className={`${styles.membership} theme-color-tertiary`}>
            Future lottery Membership
          </div>
        )}
        <div className={styles.summaryContainer_title}>Item</div>
        {monoPacks && monoPacks.size > 0 && monoPacks.map(displayDrawPackInformation)}
        {bundlePacks && bundlePacks.size > 0 && bundlePacks.map(displayBundlePackInformation)}
        <h6 className={styles.summaryContainer_totalPrice}>
          {`Total Cost   $${getTotalPrice(order)}`}
        </h6>
      </div>
      <h6 className={styles.heading}>Purchaser Information</h6>
      <div className={styles.personalDetailsWrapper}>
        <p className={styles.personalDetails}>{`${firstName} ${lastName}`}</p>
        <p className={styles.personalDetails}>{address}</p>
        <p className={styles.personalDetails}>{`${city}, ${province}, ${postalCode}`}</p>
        <p className={styles.personalDetails}>{country}</p>
        <p>
          <span className={styles.contact}>Email</span>
          {email}
        </p>
        <p>
          <span className={styles.contact}>Home Phone</span>
          {formatPhoneNumber(homePhone)}
        </p>
        {mobilePhone && (
          <p>
            <span className={styles.contact}>Mobile Phone</span>
            {formatPhoneNumber(mobilePhone)}
          </p>
        )}
      </div>

      <h6 className={styles.heading}>Names to Appear on Tickets</h6>
      <ul className={styles.nameList}>
        {printPurchaserName && <li>{`${firstName} ${lastName}`}</li>}
        <li>{printNames(ticketNames)}</li>
      </ul>
    </>
  );
};

SummaryData.propTypes = {
  purchaserInfo: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    homePhone: PropTypes.string,
    lastName: PropTypes.string,
    postalCode: PropTypes.string,
    province: PropTypes.string
  }).isRequired,

  order: ImmutablePropTypes.list.isRequired,
  subscriptionsAddedToOrder: PropTypes.bool,
  printNames: PropTypes.func.isRequired
};

SummaryData.defaultProps = {
  subscriptionsAddedToOrder: false
};

export default SummaryData;
