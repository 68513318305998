// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Styling
import './loading.css';

const Loading = React.memo(({ text }) => (
  // Inline styles should be removed in favour of proper css
  <div style={{ textAlign: 'center', padding: '2em' }}>
    <div className="lds-spinner">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
    <div className="loading-text theme-color-primary">{text}</div>
  </div>
));

Loading.propTypes = {
  text: PropTypes.string
};

Loading.defaultProps = {
  text: 'Loading'
};

export default Loading;
