// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Formik } from 'formik';
import * as Yup from 'yup';

// Utilities
import { getAttributeValidations, getAttributeInitialValues } from 'utils/attributes';

// Components
import UserForm from 'buyFlow/components/UserForm';

const setInitialValues = (initialValues, attributes) => {
  let result = initialValues || {
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    province: 'saskatchewan',
    postalCode: '',
    email: '',
    confirmEmail: '',
    homePhone: '',
    mobilePhone: '',
    groupPurchase: false,
    printPurchaserName: true,
    ticketNames: [],
    consent: false
  };

  if (attributes) {
    const unpackedAttributes = getAttributeInitialValues(attributes) || {};
    result = Object.assign(unpackedAttributes, result);
  }

  return result;
};

const PurchaserForm = ({ onSubmit, attributes, initialValues, onBlur, handleBackButton }) => {
  const formattedAttributes = attributes.toJS();
  const formValidations = getAttributeValidations(formattedAttributes);
  // Validation schema shape must match order of generated fields as the
  // <ErrorFocus /> element uses the order to determine the first error,
  // and scroll to it.
  const validationSchema = Yup.object().shape(formValidations);

  return (
    <Formik
      initialValues={setInitialValues(initialValues, formattedAttributes)}
      validationSchema={validationSchema}
      validate={onBlur} // hijacking validate prop to persist form inputs in state
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        onSubmit(values);
      }}
      render={formikProps => (
        <UserForm
          {...formikProps}
          attributes={formattedAttributes}
          handleBackButton={handleBackButton}
        />
      )}
    />
  );
};

PurchaserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  attributes: ImmutablePropTypes.list.isRequired,
  onBlur: PropTypes.func.isRequired,
  // Permit objects proptype for dynamic object (form values)
  // eslint-disable-next-line
  initialValues: PropTypes.object,
  handleBackButton: PropTypes.func.isRequired
};

PurchaserForm.defaultProps = {
  initialValues: undefined
};

export default PurchaserForm;
